const FARConstants = {

    // Mapping to display reference mic status on Live run page
    DEVICE_ONLINE_OFFLINE_STATUS: {
      true: {
        deviceStatusClass: 'awsui-util-status-positive nowrap-style',
        deviceStatusIcon: 'status-positive',
        variant: 'normal',
        deviceStatusTextStyle: 'awsui-util-ml-xs functional-passed-test-status',
        displayText: 'Online'
      },
      false: {
        deviceStatusClass: 'awsui-util-status-negative nowrap-style',
        deviceStatusIcon: 'status-negative',
        variant: 'normal',
        deviceStatusTextStyle: 'awsui-util-ml-xs functional-failed-test-status',
        displayText: 'Offline'
      },
      'Unavailable': {
        deviceStatusClass: 'awsui-util-status-negative nowrap-style',
        deviceStatusIcon: 'status-warning',
        variant: 'normal',
        deviceStatusTextStyle: 'awsui-util-ml-xs functional-error-test-status',
        displayText: 'Unavailable'
      }
    },

    // Mapping to display false wake counts on Live run page
    WAKE_COUNTS: {
      true: {
        wakeClass: 'awsui-util-status-positive nowrap-style',
        wakeTextStyle: 'awsui-util-ml-xs functional-passed-test-status',
      },
      false: {
        wakeClass: 'awsui-util-status-negative nowrap-style',
        wakeTextStyle: 'awsui-util-ml-xs functional-failed-test-status',
      }
    },

    FAR_MARKETPLACE_FEATURE_MAPPING: {
      "en_US": {
        feature: "@FAR_en_US"
      },
      "it_IT": {
        feature: "@FAR_it_IT"
      },
      "en_GB": {
        feature: "@FAR_en_GB"
      },
      "fr_FR": {
        feature: "@FAR_fr_FR"
      },
      "es_ES": {
        feature: "@FAR_es_ES"
      },
      "de_DE": {
        feature: "@FAR_de_DE"
      },
      "ja_JP": {
        feature: "@FAR_ja_JP"
      },
      "fr_CA": {
        feature: "@FAR_fr_CA"
      },
      "es_MX": {
        feature: "@FAR_es_MX"
      },
      "es_US": {
        feature: "@FAR_es_US"
      },
      "pt_BR": {
        feature: "@FAR_pt_BR"
      },
      "en_IN": {
        feature: "@FAR_en_IN"
      },
      "hi_IN": {
        feature: "@FAR_hi_IN"
      },
      "en_AU": {
        feature: "@FAR_en_AU"
      },
      "BRT_en_US": {
        feature: "@FAR_BRT_en_US"
      }
    }
}

export default FARConstants;
